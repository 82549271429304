import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'training',
  standalone: true,
  templateUrl: './training.component.html',
  imports: [RouterLink],
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent {
  firstEpisode: boolean = null;
  secondEpisode: boolean = null;
  thirdEpisode: boolean = null;
  fourthEpisode: boolean = null;
}
