<footer class="bg-dark py-5 text-white">
  <nav class="navbar navbar-expand-md navbar-light bg-dark">
    <div class="container px-0">
      <a
        class="navbar-brand pl-0 ml-0"
        [routerLink]="['/']"
      >
        <img
          src="/assets/images/mdObject-logo.svg"
          alt="mdObject logo"
        />
      </a>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item px-4">
          <a
            [routerLink]="['/home']"
            [routerLinkActive]="['active']"
            class="nav-link text-white font-weight-bold"
            >Home</a
          >
        </li>

        <li class="nav-item px-4">
          <a
            [routerLink]="['/training']"
            [routerLinkActive]="['active']"
            class="nav-link text-white font-weight-bold"
            >Training</a
          >
        </li>

        <li class="nav-item px-4">
          <a
            [routerLink]="['/about']"
            [routerLinkActive]="['active']"
            class="nav-link text-white font-weight-bold"
            >About</a
          >
        </li>

        <li class="nav-item px-4">
          <a
            [routerLink]="['/our-team']"
            [routerLinkActive]="['active']"
            class="nav-link text-white font-weight-bold"
            >Our team</a
          >
        </li>

        <li class="nav-item px-4">
          <a
            [routerLink]="['/contact']"
            [routerLinkActive]="['active']"
            class="nav-link text-white font-weight-bold"
            >Contact</a
          >
        </li>
      </ul>
    </div>
  </nav>

  <div class="clearfix"></div>

  <div class="container px-0">
    <div class="row">
      <div class="col-md-6 text-start">
        <p class="small"
          >&#169; {{ year }} mdObject, Inc. All rights reserved.</p
        >
      </div>

      <div class="col-md-6 text-end">
        <p class="small text-muted">
          Designed by
          <a
            rel="noreferrer"
            href="http://renetusa.com"
            target="_blank"
          >
            <img
              src="/assets/images/renetusa-logo.png"
              alt="renetusa logo"
            />
          </a>
        </p>
      </div>
    </div>
  </div>
</footer>
