import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[navbarToggler]',
  standalone: true
})
export class NavbarTogglerDirective {
  /** the element which closes navigation menu */
  @Input() navbarToggler: HTMLElement;
  @Input() isMobile: boolean;

  @HostListener('click') click = (): void => {
    if (
      this.isMobile &&
      this.navbarToggler &&
      typeof this.navbarToggler.click === 'function'
    ) {
      this.navbarToggler.click();
    }
  };
}
