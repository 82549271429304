import { NgxTrackerService } from '@renet-consulting/ngx-tracking-analytics';
import { HttpClient } from '@angular/common/http';
import { TrackerHttpService } from '~/services/tracker.http.service';

export function trackerHttpServiceFactory(
  trackerService: NgxTrackerService,
  http: HttpClient
): TrackerHttpService {
  return new TrackerHttpService(trackerService, http);
}
