<div id="header-anchor"></div>

<div class="wrapper-container clearfix">
  <nav-menu></nav-menu>

  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>

<ngx-tracker />

<title-strategy />
