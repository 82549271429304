import * as i0 from '@angular/core';
import { EventEmitter, Directive, Inject, Input, Output, HostListener, NgModule } from '@angular/core';
import * as i1 from '@angular/forms';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
class NgxValidatorDirective {
  constructor(ngControl) {
    this.ngControl = ngControl;
    this.validate = new EventEmitter();
    this.subscription = new Subscription();
    this.title = 'Field';
    this.blur = () => this.emitError();
    this.emitError = () => {
      if (this.ngControl.enabled && this.ngControl.touched) {
        this.validate.emit(this.getError());
      }
    };
    this.setTitle = () => {
      this.title = this.label || this.placeholder || this.title;
    };
  }
  ngOnChanges() {
    this.emitError();
    this.setTitle();
  }
  ngOnInit() {
    if (this.ngControl && this.ngControl.statusChanges) {
      this.subscription.add(this.ngControl.statusChanges.subscribe(this.emitError));
    } else {
      console.log('NgxValidatorDirective Error: this.ngControl undefined');
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  getError() {
    const errors = this.ngControl.errors;
    if (errors) {
      /**
       * {@link @angular/forms} validator errors
       * all errors interfaces you can find at https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts
       */
      if (errors.required) {
        return `The ${this.title} is required.`;
      } else if (errors.email) {
        return `The ${this.title} is invalid.`;
      } else if (errors.minlength) {
        return `The length of the ${this.title} must be at least ${errors.minlength.requiredLength} characters long.`;
      } else if (errors.maxlength) {
        return `The length of the ${this.title} must be at most ${errors.maxlength.requiredLength} characters long.`;
      } else if (errors.min) {
        return `The min value of the ${this.title} must be at least ${errors.min.min}.`;
      } else if (errors.max) {
        return `The max value of the ${this.title} must be at most ${errors.max.max}.`;
      }
      /** for all not specified and custom errors */else if (errors.errorMessage) {
        return errors.errorMessage;
      }
    }
    return null;
  }
  static {
    this.ɵfac = function NgxValidatorDirective_Factory(t) {
      return new (t || NgxValidatorDirective)(i0.ɵɵdirectiveInject(NgControl));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgxValidatorDirective,
      selectors: [["", "validate", "", "formControlName", ""], ["", "validate", "", "formControl", ""]],
      hostBindings: function NgxValidatorDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("blur", function NgxValidatorDirective_blur_HostBindingHandler() {
            return ctx.blur();
          });
        }
      },
      inputs: {
        label: "label",
        placeholder: "placeholder"
      },
      outputs: {
        validate: "validate"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxValidatorDirective, [{
    type: Directive,
    args: [{
      // tslint:disable-next-line
      selector: '[validate][formControlName], [validate][formControl]'
    }]
  }], () => [{
    type: i1.NgControl,
    decorators: [{
      type: Inject,
      args: [NgControl]
    }]
  }], {
    label: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    validate: [{
      type: Output
    }],
    blur: [{
      type: HostListener,
      args: ['blur']
    }]
  });
})();
class NgxValidatorModule {
  static {
    this.ɵfac = function NgxValidatorModule_Factory(t) {
      return new (t || NgxValidatorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxValidatorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, ReactiveFormsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxValidatorModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxValidatorDirective],
      exports: [NgxValidatorDirective],
      imports: [CommonModule, ReactiveFormsModule]
    }]
  }], null, null);
})();

// tslint:disable-next-line:max-line-length
const phoneUSRegExp = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/g;
const mismatchPasswordValidator = (name = 'password', errorMessage = 'The password and confirmation password do not match.') => control => {
  const formGroup = control.parent;
  const value = formGroup && formGroup.controls[name].value;
  return control.value !== value ? {
    errorMessage
  } : null;
};
const phoneValidator = (regExp, errorMessage = 'Invalid phone number.') => control => {
  return regExp ? new RegExp(regExp).test(control.value) ? null : {
    errorMessage
  } : null;
};

/*
 * Public API Surface of ngx-validator
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxValidatorDirective, NgxValidatorModule, mismatchPasswordValidator, phoneUSRegExp, phoneValidator };
