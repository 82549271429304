import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { ROUTES } from '~/app.routes';
import { BASE_URL } from '~/tokens/base-url.token';
import { IS_BROWSER } from '~/tokens/is-browser.token';
import { baseUrlFactory } from '~/factories/base-url.factory';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { NoneCacheInterceptor } from '@renet-consulting/interceptors';
import { baseUrlInterceptor } from '~/interceptors/api.interceptor';
import { SettingsService } from '~/services/settings.service';
import { NGX_CORE_CAPTCHA_OPTIONS } from '@renet-consulting/core-captcha';
import { CORE_CAPTCHA_OPTIONS } from '~/consts/core-captcha-options';
import { InitializerService } from '~/services/initializer.service';
import { initializerFactory } from '~/factories/initialize.factory';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import {
  NGX_BASE_URL,
  NgxTrackerHttpService,
  NgxTrackerModule,
  NgxTrackerService
} from '@renet-consulting/ngx-tracking-analytics';
import { trackerHttpServiceFactory } from '~/factories/tracker-http-service.factory';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(ROUTES),
    provideHttpClient(withInterceptors([baseUrlInterceptor])),
    { provide: BASE_URL, useFactory: baseUrlFactory },
    { provide: IS_BROWSER, useValue: true },
    { provide: HTTP_INTERCEPTORS, useClass: NoneCacheInterceptor, multi: true },
    { provide: NGX_CORE_CAPTCHA_OPTIONS, useValue: CORE_CAPTCHA_OPTIONS },
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      deps: [InitializerService],
      multi: true
    },
    importProvidersFrom(
      BrowserAnimationsModule,
      NgxPageScrollCoreModule.forRoot({ duration: SettingsService.duration }),
      NgxTrackerModule.forRoot({
        loader: {
          provide: NgxTrackerHttpService,
          useFactory: trackerHttpServiceFactory,
          deps: [NgxTrackerService, HttpClient]
        },
        baseUrl: { provide: NGX_BASE_URL, useExisting: BASE_URL }
      })
    )
  ]
};
