<main>
  <div class="container-fluid bg-white">
    <div class="container px-0 pt-4">
      <ol class="breadcrumb pt-5 pb-0 px-0 mx-0 bg-white">
        <li class="breadcrumb-item">
          <a [routerLink]="['/home']">Home</a>
        </li>

        <li class="breadcrumb-item active">Not found</li>
      </ol>
    </div>
  </div>

  <div class="clearfix"></div>

  <article>
    <div class="container-fluid text-white about not-found mt-3">
      <div class="container">
        <div class="row">
          <div class="col-md-4 d-sm-none d-md-block d-sm-none"></div>

          <div class="col-md-8 p-5">
            <h1 class="display-3 mt-5 mb-4 text-uppercase">Not found page</h1>

            <h2 class="font-weight-bold featurette-heading mb-4"
              >Oops!<span class="text-dark"> Error page &#9785;</span></h2
            >

            <p class="mb-5"
              >Go
              <a
                preventDefault
                href="/"
                (click)="onBack()"
                >back</a
              >
              or visit <a [routerLink]="['/home']">home</a> page</p
            >
          </div>
        </div>
      </div>
    </div>
  </article>
</main>
