import { Location } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'not-found',
  standalone: true,
  templateUrl: './not-found.component.html',
  imports: [RouterLink],
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  private readonly _location = inject(Location);
  private readonly _metaService = inject(Meta);

  ngOnInit(): void {
    this._metaService.updateTag({ name: 'IS_NOT_FOUND', content: '' });
  }

  onBack = () => this._location.back();
}
