import { inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  private readonly _meta = inject(Meta);

  update = (value: Partial<{ meta: Array<MetaDefinition> }>): void => {
    if (value) {
      const items = Array.isArray(value.meta) ? value.meta : [];
      items.forEach((x) => this._meta.updateTag(x));
    }
  };
}
