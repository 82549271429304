import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  NgxTrackerService,
  TrackerModel
} from '@renet-consulting/ngx-tracking-analytics';

@Injectable({
  providedIn: 'root'
})
export class TrackerHttpService {
  constructor(
    @Inject(NgxTrackerService) private readonly _trackerService: NgxTrackerService,
    @Inject(HttpClient) private readonly _http: HttpClient
  ) { }

  readonly keyId: string = 'Encrypt-Key';
  readonly ivId: string = 'Encrypt-Iv';

  addTracker = (item: TrackerModel) => {
    const keyId: string = this._trackerService.randomValue;
    const ivId: string = this._trackerService.randomValue;
    const value: string = this._trackerService.encrypt(item, keyId, ivId);

    return this._http.get(`/api/ra?model=${value}`, {
      responseType: 'text',
      headers: new HttpHeaders({
        [this.keyId]: keyId,
        [this.ivId]: ivId
      })
    });
  };
}
