import {
  HttpEvent,
  HttpInterceptorFn
} from '@angular/common/http';
import { inject } from '@angular/core';

import { Observable } from 'rxjs';
import { SettingsService } from '~/services/settings.service';

export const baseUrlInterceptor: HttpInterceptorFn = (
    req,
    next
  ): Observable<HttpEvent<any>> => {
    let service = inject(SettingsService);
    let baseUrl = service.functionUrl.getValue() ?? '';
    if (req.url.includes('https://')) {
      baseUrl = '';
    }
    const apiReq = req.clone({ url: `${baseUrl}${req.url}` });
    return next(apiReq);
  }

