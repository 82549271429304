import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { IAppSettings } from '~/interfaces/app-settings';
import { SettingsService } from './settings.service';

@Injectable({
    providedIn: 'root'
})
export class InitializerService {
    private readonly _http = inject(HttpClient);
    private readonly _settingsService = inject(SettingsService);

    init = () => this.getSettings().pipe(
        tap(x => {
            this._settingsService.functionUrl.next(x.PublicWebAppFunctionUrl);
            this._settingsService.captchaUrl.next(x.coreCaptchaFunctionUrl + 'api/CaptchaCreate');
        })
    ).toPromise();

    getSettings = () => this._http.get<IAppSettings>('/assets/website_env.json');
}
