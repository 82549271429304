import { Component, HostListener, OnInit } from '@angular/core';
import { isMobile } from '~/utils/is-mobile';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NavbarTogglerDirective } from '~/directives/navbar-toggler.directive';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
  selector: 'nav-menu',
  standalone: true,
  templateUrl: './navmenu.component.html',
  imports: [
    RouterLink,
    RouterLinkActive,
    NavbarTogglerDirective,
    MatProgressBar
  ],
  styleUrls: ['./navmenu.component.scss']
})
export class NavMenuComponent implements OnInit {
  readonly mobileWidth: number = 768;

  isMobile: boolean;
  isOpenMenu: boolean;

  ngOnInit() {
    this.setIsMobile();
  }

  @HostListener('window:resize') setIsMobile = (): void => {
    this.isMobile = isMobile();
  };
}
