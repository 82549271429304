import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject } from '@angular/core';
const LOCAL_STORAGE = new InjectionToken('LOCAL_STORAGE', {
  providedIn: 'root',
  factory: () => typeof window !== 'undefined' ? window.localStorage : null
});
const SESSION_STORAGE = new InjectionToken('SESSION_STORAGE', {
  providedIn: 'root',
  factory: () => typeof window !== 'undefined' ? window.sessionStorage : null
});

/** by default {@link StorageService} uses localStorage */
class StorageService {
  constructor(localStorage, sessionStorage) {
    this.localStorage = localStorage;
    this.sessionStorage = sessionStorage;
    /** a key for storage of value - whether will be used sessionStorage */
    this.key = 'sessional';
    this.getItem = (key, storage = this.storage) => storage && storage.getItem(key);
    this.setItem = (key, value, storage = this.storage) => storage && storage.setItem(key, value);
    /** @param sessional - whether will be used sessionStorage */
    this.setStorage = sessional => {
      this.set(this.key, sessional, this.localStorage);
      this.storage = sessional ? this.sessionStorage : this.localStorage;
    };
    this.remove = (key, storage = this.storage) => storage && storage.removeItem(key);
    this.get = (key, storage = this.storage) => {
      if (storage) {
        const value = this.getItem(key, storage);
        if (value === null) {
          return null;
        }
        try {
          return JSON.parse(value);
        } catch (e) {
          return value;
        }
      }
      return null;
    };
    this.set = (key, value, storage = this.storage) => {
      if (storage) {
        if (typeof value === 'string') {
          this.setItem(key, value, storage);
        } else {
          this.setItem(key, JSON.stringify(value), storage);
        }
      }
    };
    const sessional = this.get(this.key, this.localStorage);
    this.setStorage(sessional);
  }
  static {
    this.ɵfac = function StorageService_Factory(t) {
      return new (t || StorageService)(i0.ɵɵinject(LOCAL_STORAGE), i0.ɵɵinject(SESSION_STORAGE));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: StorageService,
      factory: StorageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [LOCAL_STORAGE]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [SESSION_STORAGE]
    }]
  }], null);
})();

/*
 * Public API Surface of storage
 */

/**
 * Generated bundle index. Do not edit.
 */

export { LOCAL_STORAGE, SESSION_STORAGE, StorageService };
