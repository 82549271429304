import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ContactModel } from '~/models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private readonly _baseUrl = 'api/';

  private readonly _http = inject(HttpClient);

  post = (model: ContactModel) =>
    this._http.post(this._baseUrl + 'PostContactUs', model);
}
