import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PageScrollOptions, PageScrollService } from 'ngx-page-scroll-core';
import { IS_BROWSER } from '~/tokens/is-browser.token';

@Injectable({
  providedIn: 'root'
})
export class ScrollingService {
  private readonly _router = inject(Router);
  private readonly _pageScrollService = inject(PageScrollService);
  private readonly _isBrowser = inject(IS_BROWSER);
  private readonly _document = inject(DOCUMENT);

  scrollTo = (options: Partial<PageScrollOptions>) =>
    this._isBrowser &&
    this._pageScrollService.scroll({
      ...(options as PageScrollOptions),
      document: this._document
    });

  scrollToAsync = (options: Partial<PageScrollOptions>) =>
    setTimeout(() => this.scrollTo(options), 4);

  scrollToPageAsync = (link, options: Partial<PageScrollOptions>) => {
    this._router.navigate([link]).then(() => this.scrollToAsync(options));
  };
}
