import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-mit',
  standalone: true,
  templateUrl: './mit.component.html',
  imports: [RouterLink],
})
export class MitComponent {
  readonly year = new Date().getFullYear();
}
