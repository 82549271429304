import * as i0 from '@angular/core';
import { EventEmitter, Directive, ViewChild, HostBinding, Input, Output, InjectionToken, Renderer2, Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Optional, Self, NgModule } from '@angular/core';
import { ProvidedControlValueAccessorBaseDirective } from '@renet-consulting/control-value-accessor';
import { Subscription } from 'rxjs';
import * as i2 from '@angular/forms';
import { NgControl, FormGroupDirective, ReactiveFormsModule } from '@angular/forms';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/material/input';
import { MatInputModule } from '@angular/material/input';
import * as i5 from '@renet-consulting/ngx-validator';
import { NgxValidatorModule } from '@renet-consulting/ngx-validator';
import { enterLeaveAnimation } from '@renet-consulting/animations';
import * as i4 from '@angular/material/form-field';
import * as i5$1 from '@angular/cdk/text-field';
const _c0 = ["inputRef"];
function NgxMatInputComponent_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.error);
  }
}
function NgxMatInputComponent_div_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelement(1, "i", 8);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", item_r3, "");
  }
}
function NgxMatInputComponent_mat_hint_12_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate2("", (ctx_r1.ngControl.control.value == null ? null : ctx_r1.ngControl.control.value.length) || 0, "/", ctx_r1.maxlength, "");
  }
}
function NgxMatInputComponent_mat_hint_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-hint");
    i0.ɵɵtemplate(1, NgxMatInputComponent_mat_hint_12_div_1_Template, 2, 2, "div", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("@enterLeave", ctx_r1.hintState);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.showHint);
  }
}
function NgxMatTextareaComponent_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r1.error);
  }
}
function NgxMatTextareaComponent_div_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelement(1, "i", 8);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r3 = ctx.$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", item_r3, "");
  }
}
function NgxMatTextareaComponent_mat_hint_12_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate2("", (ctx_r1.ngControl.control.value == null ? null : ctx_r1.ngControl.control.value.length) || 0, "/", ctx_r1.maxlength, "");
  }
}
function NgxMatTextareaComponent_mat_hint_12_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-hint");
    i0.ɵɵtemplate(1, NgxMatTextareaComponent_mat_hint_12_div_1_Template, 2, 2, "div", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("@enterLeave", ctx_r1.hintState);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.showHint);
  }
}
class InputBaseDirective extends ProvidedControlValueAccessorBaseDirective {
  constructor(ngControl, formGroup) {
    super(ngControl);
    this.formGroup = formGroup;
    this.blur = new EventEmitter();
    this.subscription = new Subscription();
    this.setErrorState = () => {
      this.errorState = this.error ? 1 : 0;
    };
    this.setHintState = () => {
      this.hintState = this.showHint ? 1 : 0;
    };
    this.setErrorsState = () => {
      this.errorsState = this.errors ? this.errors.length : 0;
    };
    this.updateControl = () => {
      this.ngControl.control.markAsDirty();
      this.ngControl.control.markAsTouched();
      this.ngControl.control.updateValueAndValidity();
    };
    this.setRequired = () => {
      const validator = this.validator;
      if (!this.required && validator) {
        const errors = validator({});
        this.required = errors && errors.required;
      }
    };
    this.setMaxlength = () => {
      const validator = this.validator;
      if (!this.maxlength && validator) {
        const value = {
          length: Infinity
        };
        const errors = validator({
          value
        });
        this.maxlength = errors && errors.maxlength && errors.maxlength.requiredLength;
      }
    };
    this.setError = value => {
      this.error = value;
      this.setErrorState();
      this.setHintState();
    };
    this.onBlur = () => this.blur.emit(this.ngControl.control.value);
    /** bug-fix for https://github.com/angular/angular/issues/7289 */
    this.setClass = () => this.classList = ['d-block', this.class].filter(x => !!x).join(' ');
  }
  ngOnChanges(e) {
    if (e.errors) {
      this.setErrorsState();
      this.setHintState();
    }
    if (e.class) {
      this.setClass();
    }
    this.setRequired();
    this.setMaxlength();
  }
  ngOnInit() {
    if (this.formGroup) {
      this.subscription.add(this.formGroup.ngSubmit.subscribe(this.updateControl));
    }
    this.setError(null);
    this.setClass();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  get showHint() {
    return !this.error && (Array.isArray(this.errors) && this.errors.length === 0 || !this.errors);
  }
  get validator() {
    return this.ngControl && this.ngControl.control && this.ngControl.control.validator;
  }
  static {
    this.ɵfac = function InputBaseDirective_Factory(t) {
      return new (t || InputBaseDirective)(i0.ɵɵdirectiveInject(i2.NgControl), i0.ɵɵdirectiveInject(i2.FormGroupDirective));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: InputBaseDirective,
      viewQuery: function InputBaseDirective_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputRef = _t.first);
        }
      },
      hostVars: 2,
      hostBindings: function InputBaseDirective_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.classList);
        }
      },
      inputs: {
        class: "class",
        placeholder: "placeholder",
        readonly: "readonly",
        required: "required",
        minlength: "minlength",
        maxlength: "maxlength",
        label: "label",
        errors: "errors"
      },
      outputs: {
        blur: "blur"
      },
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputBaseDirective, [{
    type: Directive
  }], () => [{
    type: i2.NgControl
  }, {
    type: i2.FormGroupDirective
  }], {
    inputRef: [{
      type: ViewChild,
      args: ['inputRef', {
        static: true
      }]
    }],
    classList: [{
      type: HostBinding,
      args: ['class']
    }],
    class: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    required: [{
      type: Input
    }],
    minlength: [{
      type: Input
    }],
    maxlength: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    errors: [{
      type: Input
    }],
    blur: [{
      type: Output
    }]
  });
})();
const NGX_MAX_ROWS_TEXTAREA = new InjectionToken('NGX_MAX_ROWS_TEXTAREA', {
  providedIn: 'root',
  factory: () => 15
});
const NGX_MIN_ROWS_TEXTAREA = new InjectionToken('NGX_MIN_ROWS_TEXTAREA', {
  providedIn: 'root',
  factory: () => 7
});
class NgxMatInputComponent extends InputBaseDirective {
  constructor(renderer, control, formGroup) {
    super(control, formGroup);
    this.renderer = renderer;
    this.autocomplete = 'off';
    this.type = 'text';
    this.toggleAttribute = () => {
      const el = this.inputRef && this.inputRef.nativeElement;
      if (el) {
        const name = 'name';
        this.name ? this.renderer.setAttribute(el, name, this.name) : this.renderer.removeAttribute(el, name);
      }
    };
  }
  ngOnChanges(e) {
    super.ngOnChanges(e);
    if (e.name) {
      this.toggleAttribute();
    }
  }
  ngOnInit() {
    super.ngOnInit();
    this.toggleAttribute();
  }
  static {
    this.ɵfac = function NgxMatInputComponent_Factory(t) {
      return new (t || NgxMatInputComponent)(i0.ɵɵdirectiveInject(Renderer2), i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(FormGroupDirective, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxMatInputComponent,
      selectors: [["ngx-mat-input"]],
      inputs: {
        autocomplete: "autocomplete",
        name: "name",
        type: "type"
      },
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature],
      decls: 13,
      vars: 15,
      consts: [["inputRef", ""], [1, "w-100", "mt-3"], ["matInput", "", 3, "validate", "blur", "autocomplete", "label", "type", "required", "minlength", "maxlength", "readonly", "formControl", "placeholder"], [1, "d-flex", "justify-content-between", "minh-28"], ["class", "mb-1", 4, "ngIf"], ["class", "mb-1", 4, "ngFor", "ngForOf"], [4, "ngIf"], [1, "mb-1"], [1, "fas", "fa-exclamation-circle"], ["class", "mt-1", 4, "ngIf"], [1, "mt-1"]],
      template: function NgxMatInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "mat-form-field", 1)(1, "mat-label");
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "input", 2, 0);
          i0.ɵɵlistener("validate", function NgxMatInputComponent_Template_input_validate_3_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.setError($event));
          })("blur", function NgxMatInputComponent_Template_input_blur_3_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onBlur());
          });
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(5, "div", 3)(6, "div")(7, "mat-error");
          i0.ɵɵtemplate(8, NgxMatInputComponent_div_8_Template, 2, 1, "div", 4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(9, "mat-error");
          i0.ɵɵtemplate(10, NgxMatInputComponent_div_10_Template, 3, 1, "div", 5);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(11, "div");
          i0.ɵɵtemplate(12, NgxMatInputComponent_mat_hint_12_Template, 2, 2, "mat-hint", 6);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.placeholder);
          i0.ɵɵadvance();
          i0.ɵɵproperty("autocomplete", ctx.autocomplete)("label", ctx.label)("type", ctx.type)("required", ctx.required)("minlength", ctx.minlength)("maxlength", ctx.maxlength)("readonly", ctx.readonly)("formControl", ctx.ngControl.control)("placeholder", ctx.placeholder);
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("@enterLeave", ctx.errorState);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.error);
          i0.ɵɵadvance();
          i0.ɵɵproperty("@enterLeave", ctx.errorsState);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.errors);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.maxlength);
        }
      },
      dependencies: [i1.NgForOf, i1.NgIf, i2.DefaultValueAccessor, i2.NgControlStatus, i2.RequiredValidator, i2.MinLengthValidator, i2.MaxLengthValidator, i2.FormControlDirective, i3.MatInput, i4.MatFormField, i4.MatLabel, i4.MatHint, i4.MatError, i5.NgxValidatorDirective],
      styles: ["ngx-mat-input .minh-28{min-height:28px}ngx-mat-input mat-error div{overflow:hidden}ngx-mat-input mat-hint{display:block;direction:rtl}ngx-mat-input mat-hint div{overflow:hidden}ngx-mat-input .mat-form-field-appearance-legacy .mat-form-field-underline{position:relative;bottom:0}ngx-mat-input .mat-form-field-appearance-legacy .mat-form-field-wrapper{padding-bottom:0}ngx-mat-input .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper{position:relative;margin-top:initial;top:initial}ngx-mat-input .mat-form-field-wrapper{padding-bottom:0}\n"],
      encapsulation: 2,
      data: {
        animation: [enterLeaveAnimation]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatInputComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-mat-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      animations: [enterLeaveAnimation],
      template: "<mat-form-field class=\"w-100 mt-3\">\r\n    <mat-label>{{placeholder}}</mat-label>\r\n    <input #inputRef matInput [autocomplete]=\"autocomplete\" [label]=\"label\" [type]=\"type\" [required]=\"required\" [minlength]=\"minlength\" [maxlength]=\"maxlength\"\r\n           [readonly]=\"readonly\" [formControl]=\"ngControl.control\" [placeholder]=\"placeholder\" (validate)=\"setError($event)\" (blur)=\"onBlur()\">\r\n</mat-form-field>\r\n<div class=\"d-flex justify-content-between minh-28\">\r\n    <div>\r\n        <mat-error [@enterLeave]=\"errorState\">\r\n            <div *ngIf=\"error\" class=\"mb-1\">{{error}}</div>\r\n        </mat-error>\r\n        <mat-error [@enterLeave]=\"errorsState\">\r\n            <div *ngFor=\"let item of errors\" class=\"mb-1\"><i class=\"fas fa-exclamation-circle\"></i> {{item}}</div>\r\n        </mat-error>\r\n    </div>\r\n    <div>\r\n        <mat-hint *ngIf=\"maxlength\" [@enterLeave]=\"hintState\">\r\n            <div *ngIf=\"showHint\" class=\"mt-1\">{{ngControl.control.value?.length || 0}}/{{maxlength}}</div>\r\n        </mat-hint>\r\n    </div>\r\n</div>\r\n",
      styles: ["ngx-mat-input .minh-28{min-height:28px}ngx-mat-input mat-error div{overflow:hidden}ngx-mat-input mat-hint{display:block;direction:rtl}ngx-mat-input mat-hint div{overflow:hidden}ngx-mat-input .mat-form-field-appearance-legacy .mat-form-field-underline{position:relative;bottom:0}ngx-mat-input .mat-form-field-appearance-legacy .mat-form-field-wrapper{padding-bottom:0}ngx-mat-input .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper{position:relative;margin-top:initial;top:initial}ngx-mat-input .mat-form-field-wrapper{padding-bottom:0}\n"]
    }]
  }], () => [{
    type: i0.Renderer2,
    decorators: [{
      type: Inject,
      args: [Renderer2]
    }]
  }, {
    type: i2.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }, {
      type: Inject,
      args: [NgControl]
    }]
  }, {
    type: i2.FormGroupDirective,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [FormGroupDirective]
    }]
  }], {
    autocomplete: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    type: [{
      type: Input
    }]
  });
})();
class NgxMatTextareaComponent extends InputBaseDirective {
  constructor(control, formGroup, minRows, maxRows) {
    super(control, formGroup);
    this.minRows = minRows;
    this.maxRows = maxRows;
  }
  static {
    this.ɵfac = function NgxMatTextareaComponent_Factory(t) {
      return new (t || NgxMatTextareaComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(FormGroupDirective, 8), i0.ɵɵdirectiveInject(NGX_MIN_ROWS_TEXTAREA, 8), i0.ɵɵdirectiveInject(NGX_MAX_ROWS_TEXTAREA, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxMatTextareaComponent,
      selectors: [["ngx-mat-textarea"]],
      inputs: {
        minRows: "minRows",
        maxRows: "maxRows"
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 13,
      vars: 15,
      consts: [["inputRef", ""], [1, "w-100", "mt-3"], ["matInput", "", "cdkTextareaAutosize", "", 3, "validate", "blur", "cdkAutosizeMinRows", "cdkAutosizeMaxRows", "label", "required", "minlength", "maxlength", "readonly", "formControl", "placeholder"], [1, "d-flex", "justify-content-between", "minh-28"], ["class", "mb-1", 4, "ngIf"], ["class", "mb-1", 4, "ngFor", "ngForOf"], [4, "ngIf"], [1, "mb-1"], [1, "fas", "fa-exclamation-circle"], ["class", "mt-1", 4, "ngIf"], [1, "mt-1"]],
      template: function NgxMatTextareaComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "mat-form-field", 1)(1, "mat-label");
          i0.ɵɵtext(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "textarea", 2, 0);
          i0.ɵɵlistener("validate", function NgxMatTextareaComponent_Template_textarea_validate_3_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.setError($event));
          })("blur", function NgxMatTextareaComponent_Template_textarea_blur_3_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onBlur());
          });
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(5, "div", 3)(6, "div")(7, "mat-error");
          i0.ɵɵtemplate(8, NgxMatTextareaComponent_div_8_Template, 2, 1, "div", 4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(9, "mat-error");
          i0.ɵɵtemplate(10, NgxMatTextareaComponent_div_10_Template, 3, 1, "div", 5);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(11, "div");
          i0.ɵɵtemplate(12, NgxMatTextareaComponent_mat_hint_12_Template, 2, 2, "mat-hint", 6);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate(ctx.placeholder);
          i0.ɵɵadvance();
          i0.ɵɵproperty("cdkAutosizeMinRows", ctx.minRows)("cdkAutosizeMaxRows", ctx.maxRows)("label", ctx.label)("required", ctx.required)("minlength", ctx.minlength)("maxlength", ctx.maxlength)("readonly", ctx.readonly)("formControl", ctx.ngControl == null ? null : ctx.ngControl.control)("placeholder", ctx.placeholder);
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("@enterLeave", ctx.errorState);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.error);
          i0.ɵɵadvance();
          i0.ɵɵproperty("@enterLeave", ctx.errorsState);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.errors);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.maxlength);
        }
      },
      dependencies: [i1.NgForOf, i1.NgIf, i2.DefaultValueAccessor, i2.NgControlStatus, i2.RequiredValidator, i2.MinLengthValidator, i2.MaxLengthValidator, i2.FormControlDirective, i3.MatInput, i4.MatFormField, i4.MatLabel, i4.MatHint, i4.MatError, i5$1.CdkTextareaAutosize, i5.NgxValidatorDirective],
      styles: ["ngx-mat-textarea .minh-28{min-height:28px}ngx-mat-textarea mat-error div{overflow:hidden}ngx-mat-textarea mat-hint{display:block;direction:rtl}ngx-mat-textarea mat-hint div{overflow:hidden}ngx-mat-textarea .mat-form-field-appearance-legacy .mat-form-field-underline{position:relative;bottom:0}ngx-mat-textarea .mat-form-field-appearance-legacy .mat-form-field-wrapper{padding-bottom:0}ngx-mat-textarea .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper{position:relative;margin-top:initial;top:initial}ngx-mat-textarea .mat-form-field-wrapper{padding-bottom:0}\n"],
      encapsulation: 2,
      data: {
        animation: [enterLeaveAnimation]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatTextareaComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-mat-textarea',
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      animations: [enterLeaveAnimation],
      template: "<mat-form-field class=\"w-100 mt-3\">\r\n    <mat-label>{{placeholder}}</mat-label>\r\n    <textarea #inputRef matInput cdkTextareaAutosize [cdkAutosizeMinRows]=\"minRows\" [cdkAutosizeMaxRows]=\"maxRows\" [label]=\"label\" [required]=\"required\"\r\n              [minlength]=\"minlength\" [maxlength]=\"maxlength\" [readonly]=\"readonly\" [formControl]=\"ngControl?.control\" [placeholder]=\"placeholder\"\r\n              (validate)=\"setError($event)\" (blur)=\"onBlur()\"></textarea>\r\n</mat-form-field>\r\n<div class=\"d-flex justify-content-between minh-28\">\r\n    <div>\r\n        <mat-error [@enterLeave]=\"errorState\">\r\n            <div *ngIf=\"error\" class=\"mb-1\">{{error}}</div>\r\n        </mat-error>\r\n        <mat-error [@enterLeave]=\"errorsState\">\r\n            <div *ngFor=\"let item of errors\" class=\"mb-1\"><i class=\"fas fa-exclamation-circle\"></i> {{item}}</div>\r\n        </mat-error>\r\n    </div>\r\n    <div>\r\n        <mat-hint *ngIf=\"maxlength\" [@enterLeave]=\"hintState\">\r\n            <div *ngIf=\"showHint\" class=\"mt-1\">{{ngControl.control.value?.length || 0}}/{{maxlength}}</div>\r\n        </mat-hint>\r\n    </div>\r\n</div>\r\n",
      styles: ["ngx-mat-textarea .minh-28{min-height:28px}ngx-mat-textarea mat-error div{overflow:hidden}ngx-mat-textarea mat-hint{display:block;direction:rtl}ngx-mat-textarea mat-hint div{overflow:hidden}ngx-mat-textarea .mat-form-field-appearance-legacy .mat-form-field-underline{position:relative;bottom:0}ngx-mat-textarea .mat-form-field-appearance-legacy .mat-form-field-wrapper{padding-bottom:0}ngx-mat-textarea .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper{position:relative;margin-top:initial;top:initial}ngx-mat-textarea .mat-form-field-wrapper{padding-bottom:0}\n"]
    }]
  }], () => [{
    type: i2.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }, {
      type: Inject,
      args: [NgControl]
    }]
  }, {
    type: i2.FormGroupDirective,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [FormGroupDirective]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [NGX_MIN_ROWS_TEXTAREA]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [NGX_MAX_ROWS_TEXTAREA]
    }]
  }], {
    minRows: [{
      type: Input
    }],
    maxRows: [{
      type: Input
    }]
  });
})();
class NgxMatInputModule {
  static {
    this.ɵfac = function NgxMatInputModule_Factory(t) {
      return new (t || NgxMatInputModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxMatInputModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, ReactiveFormsModule, MatInputModule, NgxValidatorModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxMatInputModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxMatInputComponent, NgxMatTextareaComponent],
      exports: [NgxMatInputComponent, NgxMatTextareaComponent],
      imports: [CommonModule, ReactiveFormsModule, MatInputModule, NgxValidatorModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-mat-input
 */

/**
 * Generated bundle index. Do not edit.
 */

export { InputBaseDirective, NGX_MAX_ROWS_TEXTAREA, NGX_MIN_ROWS_TEXTAREA, NgxMatInputComponent, NgxMatInputModule, NgxMatTextareaComponent };
