<main>
  <div class="container-fluid bg-white">
    <div class="container px-0 pt-4">
      <ol class="breadcrumb pt-5 pb-0 px-0 mx-0 bg-white">
        <li class="breadcrumb-item">
          <a [routerLink]="['/home']">Home</a>
        </li>
        <li class="breadcrumb-item active">Our team</li>
      </ol>
    </div>
  </div>

  <div class="clearfix"></div>

  <div class="container our-team clearfix">
    <h1 class="text-uppercase display-3 text-center text-dark mb-4"
      ><span class="text-dark">Our</span> Team</h1
    >

    <div class="row d-flex align-items-stretch bd-highlight">
      <div
        class="col-lg-6 text-center border border-light bd-highlight p-5 float-left"
      >
        <img
          class="mb-3"
          src="/assets/images/Alexsander-Alstotsky-img.jpg"
          alt="Alex Altotsky image"
          title="Alex Altotsky, CEO and Senior Software Architect"
        />

        <h2>Alexander Altotsky</h2>

        <h3 class="text-secondary text-secondary h5 mb-3"
          >Sr. Principal Consultant, Senior Software Architect</h3
        >

        <p class="text-left"
          >As a Principal Consultant at the company, my focus is on developing
          successful relationships with our partners and clients and delivering
          the best quality software solutions at the minimum cost.</p
        >

        <p class="text-left"
          >With over 30 years in software and computer industry, a master degree
          in computer science and applied math, and being Certified Scrum
          Master, a Product Owner and Solution Architect for over 20 years, I' m
          producing significant cost savings to every client' s project.</p
        >
      </div>

      <div
        class="col-lg-6 text-center border border-light bd-highlight p-5 float-left"
      >
        <img
          class="mb-3"
          src="/assets/images/Arie-Baratt-img.jpg"
          alt="Arie Baratt"
          title="Arie Baratt, Sr. Data Science, Machine Learning(ML), and Artificial Intelligence (AI) Consultant"
        />

        <h2>Arie Baratt</h2>

        <h3 class="text-secondary text-secondary h5 mb-3"
          >Sr. Data Science, Machine Learning(ML), and Artificial Intelligence
          (AI) Consultant</h3
        >

        <p class="text-left"
          >Although I started my career as a physicist, most of the research I
          conducted as an applied mathematician, mainly in the area of
          computational biology.</p
        >

        <p class="text-left"
          >Through my work in academia and industry, I acquired a broad set of
          skills and expertise. These include modeling of complex dynamical
          systems, statistical analysis, and methods of machine learning and
          artificial intelligence.</p
        >

        <p class="text-left"
          >My broad background allows me to analyze a dataset using both
          deterministic and statistical approaches which often leads to
          remarkable breakthroughs and discovery of the hidden patterns.</p
        >
      </div>

      <div
        class="col-lg-6 text-center border border-light bd-highlight p-5 float-left"
      >
        <img
          class="mb-3"
          src="/assets/images/Andrey-Medvedev-img.jpg"
          alt="Andrew Medvedev image"
          title="Andrew Medvedev, Back-End Developer"
        />

        <h2>Andrew Medvedev</h2>

        <h3 class="text-secondary text-secondary h5 mb-3"
          >Back-End Developer</h3
        >

        <p class="text-left"
          >I am an experienced back-end developer, specializing in development
          of websites, applications, and software for enterprises.</p
        >

        <p class="text-left"
          >I work with programming languages such as C# and PHP. I strive to
          refine my knowledge in the field of information technology
          consistently, in order to improve my skills. I perform my tasks
          efficiently and with quality.
        </p>
      </div>

      <!--<div class="col-lg-6 text-center border border-light bd-highlight p-5 float-left">
                <img class="mb-3" src="/assets/images/Roman-Matiuk-img.jpg" alt="Roman Matiuk image" title="Roman Matiuk, Front-End Developer" />
                <h2>Roman Matiuk</h2>
                <h3 class="text-secondary text-secondary h5 mb-3">Front-End Developer</h3>
                <p class="text-left">I am a front-end developer with experience in web design. As a front-end developer, I responsible for implementing visual elements that users see and interact within a web or mobile application.</p>
                <p class="text-left">I have developed, launched, and maintained multiple projects, carrying the development of its front-end with Angular, JavaScript, TypeScript, and HTML. My strongest asset is that I am an interlink between the graphics, front-end, and back-end.</p>
            </div>-->

      <div
        class="col-lg-6 text-center border border-light bd-highlight p-5 float-left"
      >
        <img
          class="mb-3"
          src="/assets/images/Elena-Doroshenko-img.jpg"
          alt="Helen Doroshenko image"
          title="Helen Doroshenko, web designer"
        />

        <h2>Helen Doroshenko</h2>

        <h3 class="text-secondary text-secondary h5 mb-3">Web Designer</h3>

        <p class="text-left"
          >Highly seasoned and creative Web Designer with strong work ethic,
          customer service and satisfaction record. 9+ years of experience in
          Web/UI/UX, web applications and landing pages. While working on a
          project I always take into account the specifics and future usability
          of the site. Adept multitasker capable of bringing simultaneous web
          page creation and repair projects to completion with full accuracy and
          efficiency.</p
        >

        <p class="text-left"
          >Extensive experience with Adobe Photoshop, Adobe Illustrator, CSS3,
          HTML5, Bootstrap and others. As an innovative and evolving web
          designer I seek out new trends in European and North America designs
          and latest trends in UI.</p
        >
      </div>

      <!--<div class="col-lg-6 text-center border border-light bd-highlight p-5 float-left d-sm-none d-md-flex d-lg-flex"></div>-->

      <div class="clearfix"></div>
    </div>
  </div>
</main>
