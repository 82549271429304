import {
  Component,
  inject,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import {
  CoreCaptchaComponent,
  CoreCaptchaModule,
  CoreCaptchaRequired
} from '@renet-consulting/core-captcha';
import {
  NgxTrackerHttpService,
  NgxTrackerService,
  TrackerModel
} from '@renet-consulting/ngx-tracking-analytics';
import { phoneUSRegExp, phoneValidator } from '@renet-consulting/ngx-validator';
import { ContactModel } from '~/models/contact.model';
import { ContactService } from '~/services/contact.service';
import { BASE_URL } from '~/tokens/base-url.token';
import { SettingsService } from '~/services/settings.service';
import { MapPick } from '../../../typings';
import { MatDialog, MatDialogClose } from '@angular/material/dialog';
import { NgxMatInputModule } from '@renet-consulting/ngx-mat-input';
import { MatError, MatFormField } from '@angular/material/form-field';
import { AsyncPipe } from '@angular/common';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'contact',
  standalone: true,
  templateUrl: './contact.component.html',
  imports: [
    RouterLink,
    ReactiveFormsModule,
    NgxMatInputModule,
    MatError,
    CoreCaptchaModule,
    AsyncPipe,
    MatDialogClose,
    MatFormField,
    MatInput
  ],
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('modalRef', { static: true }) modalRef: TemplateRef<string>;
  @ViewChild(CoreCaptchaComponent) captchaComponent: CoreCaptchaComponent;

  private readonly _baseUrl = inject(BASE_URL);
  private readonly _router = inject(Router);
  private readonly _title = inject(Title);
  private readonly _matDialog = inject(MatDialog);
  private readonly _contactService = inject(ContactService);
  private readonly _settingsService = inject(SettingsService);
  private readonly _trackerHttpService = inject(NgxTrackerHttpService);
  private readonly _trackerService = inject(NgxTrackerService);

  readonly minlength = 10;
  readonly mailto = 'mailto:mdsupport@mdobject.com';
  readonly captchaUrl = this._settingsService.captchaUrl;

  formGroup: UntypedFormGroup;
  errorMessage: string;

  ngOnInit() {
    this._setForm();
  }

  submit = (): void => {
    if (this.formGroup.valid) {
      this._contactService.post(this.formGroup.value).subscribe(() => {
        this._addTracker();
        this.formGroup.reset();
        this.captchaComponent.refresh();
        this.formGroup.markAsPristine();
        this.formGroup.markAsUntouched();
        this._matDialog.open(this.modalRef, { width: 'max(300px, 40%)' });
      });
    }
  };

  private _setForm(): void {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      phone: new UntypedFormControl('', [
        Validators.required,
        phoneValidator(phoneUSRegExp)
      ]),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email
      ]),
      message: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(this.minlength)
      ]),
      captcha: new UntypedFormControl(null, [CoreCaptchaRequired])
    } as MapPick<ContactModel, keyof ContactModel, UntypedFormControl>);
  }

  private _addTracker = (): void => {
    const model: Partial<TrackerModel> | any = {
      ...this._trackerService.trackerModel,
      t: 'event',
      ec: 'SendForm',
      ea: 'Submit',
      dl: `${this._baseUrl}${this._router.url}`,
      dp: this._router.url,
      dt: this._title.getTitle()
    };

    this._trackerHttpService.addTracker(model as TrackerModel).subscribe();
  };
}
