<main>
  <div class="container-fluid bg-white">
    <div class="container px-0 pt-4">
      <ol class="breadcrumb pt-5 pb-0 px-0 mx-0 bg-white">
        <li class="breadcrumb-item">
          <a [routerLink]="['/home']">Home</a>
        </li>

        <li class="breadcrumb-item active">About</li>
      </ol>
    </div>
  </div>

  <div class="clearfix"></div>

  <article>
    <div class="container-fluid text-white about mt-3">
      <div class="container">
        <div class="row">
          <div class="col"></div>

          <div
            id="about-us"
            class="col-md-8 p-5"
          >
            <h2 class="featurette-heading text-uppercase mb-4"
              ><strong>About mdObject Inc.</strong></h2
            >

            <p
              >The mdObject stands for Medical Data Object. mdObject Inc. is a
              healthcare technology corporation formed under Wyoming law. Our
              goals to help public and private organizations improve their IT
              healthcare infrastructure and interoperability between different
              healthcare systems.</p
            >

            <p
              >We are a young company. mdObject Inc formed in March 2015 under
              the law of Nevada. We moved under the law of Wyoming in March
              2017. The company is well known for our Open Source
              <a href="https://github.com/mdObject/GECentricity">$mdObject</a>
              solution. Our "HTML Forms development for GE Centricity Systems
              public training materials have over 1490 combined healthcare
              viewers.</p
            >

            <p
              >The source of our finances comes from investors, donations from
              the public and private organizations, and fee-based services.</p
            >
          </div>
        </div>
      </div>
    </div>
  </article>
</main>
