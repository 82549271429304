import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'about',
  standalone: true,
  templateUrl: './about.component.html',
  imports: [RouterLink],
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {
}
