import { __decorate, __param } from 'tslib';
import * as i0 from '@angular/core';
import { Inject, Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
let ApiPrefixInterceptor = class ApiPrefixInterceptor {
  constructor(prefix) {
    this.prefix = prefix;
  }
  intercept(request, next) {
    if (!/^(http|https):/i.test(request.url)) {
      request = request.clone({
        url: `${this.prefix}${request.url}`
      });
    }
    return next.handle(request);
  }
};
ApiPrefixInterceptor = __decorate([__param(0, Inject(String))], ApiPrefixInterceptor);
class ExtractErrorInterceptor {
  constructor() {}
  intercept(request, handler) {
    return handler.handle(request).pipe(catchError(e => throwError(e.error)));
  }
  static {
    this.ɵfac = function ExtractErrorInterceptor_Factory(t) {
      return new (t || ExtractErrorInterceptor)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ExtractErrorInterceptor,
      factory: ExtractErrorInterceptor.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ExtractErrorInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class NoneCacheInterceptor {
  constructor() {}
  intercept(request, handler) {
    return handler.handle(request.clone({
      setHeaders: {
        'Cache-Control': 'no-store, no-cache, must-revalidate',
        Pragma: 'no-cache'
      }
    }));
  }
  static {
    this.ɵfac = function NoneCacheInterceptor_Factory(t) {
      return new (t || NoneCacheInterceptor)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NoneCacheInterceptor,
      factory: NoneCacheInterceptor.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NoneCacheInterceptor, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();

/*
 * Public API Surface of interceptors
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ApiPrefixInterceptor, ExtractErrorInterceptor, NoneCacheInterceptor };
