import {
  Component,
  DestroyRef,
  inject,
  OnInit
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { MetadataService } from '~/services/link.service';
import { ScrollingService } from '~/services/scrolling.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgxTrackerModule } from '@renet-consulting/ngx-tracking-analytics';
import { TitleStrategyModule } from '@renet-consulting/title-strategy';
import { FooterComponent } from '~/components/footer/footer.component';
import { NavMenuComponent } from '~/components/navmenu/navmenu.component';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet,
    NgxTrackerModule,
    TitleStrategyModule,
    FooterComponent,
    NavMenuComponent
  ],
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly _router = inject(Router);
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _metadataService = inject(MetadataService);
  private readonly _scrollingService = inject(ScrollingService);
  private readonly _destroyRef = inject(DestroyRef);

  ngOnInit() {
    this._router.events
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((e) => e instanceof NavigationEnd),
        tap(this.scrollToTop),
        map(() => this._activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe({
        next: this._metadataService.update
      });
  }

  scrollToTop = () => {
    this._scrollingService.scrollToAsync({ scrollTarget: '#header-anchor' });
  };
}
