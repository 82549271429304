import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    static readonly duration = 400;
    readonly captchaUrl = new BehaviorSubject<string>(null);
    readonly functionUrl = new BehaviorSubject<string>(null);
}
