import * as i0 from '@angular/core';
import { Directive } from '@angular/core';
import * as i1 from '@angular/forms';
class ControlValueAccessorBaseDirective {
  constructor() {}
  writeValue(x) {
    this.value = x;
    if (this.onChange) {
      this.onChange(x);
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(x) {
    this.disabled = x;
  }
  static {
    this.ɵfac = function ControlValueAccessorBaseDirective_Factory(t) {
      return new (t || ControlValueAccessorBaseDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ControlValueAccessorBaseDirective
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ControlValueAccessorBaseDirective, [{
    type: Directive
  }], () => [], null);
})();
class ProvidedControlValueAccessorBaseDirective extends ControlValueAccessorBaseDirective {
  constructor(ngControl) {
    super();
    this.ngControl = ngControl;
    if (this.ngControl) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }
  static {
    this.ɵfac = function ProvidedControlValueAccessorBaseDirective_Factory(t) {
      return new (t || ProvidedControlValueAccessorBaseDirective)(i0.ɵɵdirectiveInject(i1.NgControl));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ProvidedControlValueAccessorBaseDirective,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProvidedControlValueAccessorBaseDirective, [{
    type: Directive
  }], () => [{
    type: i1.NgControl
  }], null);
})();

/*
 * Public API Surface of control-value-accessor
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ControlValueAccessorBaseDirective, ProvidedControlValueAccessorBaseDirective };
