import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, Optional, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import * as i1$1 from '@angular/router';
import { NavigationEnd, Router, ActivatedRoute, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as i1 from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
const DEFAULT_TITLE_TOKEN = new InjectionToken('default_title_token');
class TitleStrategyService {
  constructor(title, defaultTitle) {
    this.title = title;
    this.defaultTitle = defaultTitle;
    this.set = value => {
      const title = value.title || this.defaultTitle;
      if (title) {
        this.title.setTitle(title);
      }
    };
  }
  static {
    this.ɵfac = function TitleStrategyService_Factory(t) {
      return new (t || TitleStrategyService)(i0.ɵɵinject(Title), i0.ɵɵinject(DEFAULT_TITLE_TOKEN, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TitleStrategyService,
      factory: TitleStrategyService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TitleStrategyService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.Title,
    decorators: [{
      type: Inject,
      args: [Title]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DEFAULT_TITLE_TOKEN]
    }, {
      type: Optional
    }]
  }], null);
})();
class TitleStrategyComponent {
  constructor(router, route, titleStrategy) {
    this.router = router;
    this.route = route;
    this.titleStrategy = titleStrategy;
    this.subscription = new Subscription();
  }
  ngOnInit() {
    this.subscription.add(this.router.events.pipe(filter(e => e instanceof NavigationEnd), map(() => this.route.snapshot), map(r => {
      while (r.firstChild) {
        r = r.firstChild;
      }
      return r;
    }), map(x => x.data)).subscribe(this.titleStrategy.set));
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  static {
    this.ɵfac = function TitleStrategyComponent_Factory(t) {
      return new (t || TitleStrategyComponent)(i0.ɵɵdirectiveInject(Router), i0.ɵɵdirectiveInject(ActivatedRoute), i0.ɵɵdirectiveInject(TitleStrategyService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TitleStrategyComponent,
      selectors: [["title-strategy"]],
      decls: 0,
      vars: 0,
      template: function TitleStrategyComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TitleStrategyComponent, [{
    type: Component,
    args: [{
      selector: 'title-strategy',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: i1$1.Router,
    decorators: [{
      type: Inject,
      args: [Router]
    }]
  }, {
    type: i1$1.ActivatedRoute,
    decorators: [{
      type: Inject,
      args: [ActivatedRoute]
    }]
  }, {
    type: TitleStrategyService,
    decorators: [{
      type: Inject,
      args: [TitleStrategyService]
    }]
  }], null);
})();
class TitleStrategyModule {
  static {
    this.ɵfac = function TitleStrategyModule_Factory(t) {
      return new (t || TitleStrategyModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TitleStrategyModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [RouterModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TitleStrategyModule, [{
    type: NgModule,
    args: [{
      declarations: [TitleStrategyComponent],
      exports: [TitleStrategyComponent],
      imports: [RouterModule]
    }]
  }], null, null);
})();

/*
 * Public API Surface of title-strategy
 */

/**
 * Generated bundle index. Do not edit.
 */

export { TitleStrategyComponent, TitleStrategyModule, TitleStrategyService };
