import * as i0 from '@angular/core';
import { InjectionToken, Injectable, PLATFORM_ID, Inject, Component, ChangeDetectionStrategy, Input, NgModule } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import * as i4 from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import * as i3 from '@angular/router';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import * as i2 from '@renet-consulting/ngx-http-params';
import { NgxHttpParamsService } from '@renet-consulting/ngx-http-params';
import * as i1 from '@renet-consulting/storage';
import { StorageService } from '@renet-consulting/storage';
import { enc, mode, pad, AES } from 'crypto-js';
const NGX_BASE_URL = new InjectionToken('ngx-base-url');
class NgxTrackerHttpService {}
class NgxTrackerHttpFakeService extends NgxTrackerHttpService {
  addTracker(x) {
    return of({} || x);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNgxTrackerHttpFakeService_BaseFactory;
      return function NgxTrackerHttpFakeService_Factory(t) {
        return (ɵNgxTrackerHttpFakeService_BaseFactory || (ɵNgxTrackerHttpFakeService_BaseFactory = i0.ɵɵgetInheritedFactory(NgxTrackerHttpFakeService)))(t || NgxTrackerHttpFakeService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxTrackerHttpFakeService,
      factory: NgxTrackerHttpFakeService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTrackerHttpFakeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/** descriptions of keys see in the https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters */
class TrackerModel {
  constructor() {
    /**
     * Protocol Version
     * Required for all hit types.
     * The Protocol version. The current value is "1".
     * This will only change when there are changes made that are not backwards compatible.
     */
    this.v = '2';
    /**
     * Campaign Medium
     * Optional.
     * Specifies the campaign medium.
     */
    this.cm = null;
    /**
     * Campaign Source
     * Optional.
     * Specifies the campaign source.
     */
    this.cs = null;
    /**
     * Client ID
     * Optional.
     * This field is required if User ID (uid) is not specified in the request.
     * This anonymously identifies a particular user, device, or browser instance.
     * For the web, this is generally stored as a first-party cookie with a two-year expiration.
     * For mobile apps, this is randomly generated for each particular instance of an application install.
     * The value of this field should be a random UUID (version 4) as described in http://www.ietf.org/rfc/rfc4122.txt.
     */
    this.cid = null;
    /**
     * Document Encoding
     * Optional.
     * Specifies the character set used to encode the page / document.
     */
    this.de = null;
    /**
     * Event Action
     * Required for event hit type.
     * Specifies the event action. Must not be empty.
     */
    this.ea = null;
    /**
     * Event Category
     * Required for event hit type.
     * Specifies the event category. Must not be empty.
     */
    this.ec = null;
    /**
     * Event Value
     * Optional.
     * Specifies the event value. Values must be non-negative.
     */
    this.ev = null;
    /**
     * Event Label
     * Optional.
     * Specifies the event label.
     */
    this.el = null;
    /**
     * Hit type
     * Required for all hit types.
     * The type of hit. Must be one of "pageview", "screenview", "event", "transaction", "item", "social", "exception", "timing".
     */
    this.en = 'pageview';
    /**
     * Java Enabled
     * Optional.
     * Specifies whether Java was enabled.
     */
    this.je = '0';
    /**
     * User Language
     * Optional.
     * Specifies the language.
     */
    this.ul = null;
    /**
     * Document location URL
     * Optional.
     * Use this parameter to send the full URL (document location) of the page on which content resides.
     * You can use the &dh and &dp parameters to override the hostname and path + query portions of the document location, accordingly.
     * The JavaScript clients determine this parameter using the concatenation of
     * the document.location.origin + document.location.pathname + document.location.search browser parameters.
     * Be sure to remove any user authentication or other private information from the URL if present.
     * For "pageview" hits, either &dl or both &dh and &dp have to be specified for the hit to be valid.
     */
    this.dl = null;
    /**
     * Document Path
     * Optional.
     * The path portion of the page URL. Should begin with "/".
     * For "pageview" hits, either &dl or both &dh and &dp have to be specified for the hit to be valid.
     */
    this.dp = null;
    /**
     * Document Referrer
     * Optional.
     * Specifies which referral source brought traffic to a website.
     * This value is also used to compute the traffic source.
     * The format of this value is a URL.
     */
    this.dr = null;
    /**
     * Screen Colors
     * Optional.
     * Specifies the screen color depth.
     */
    this.sd = null;
    /**
     * Screen Resolution
     * Optional.
     * Specifies the screen resolution.
     */
    this.sr = null;
    /**
     * Document Title
     * Optional.
     * The title of the page / document.
     */
    this.dt = null;
    /**
     * Tracking ID / Web Property ID
     * Required for all hit types.
     * The tracking ID / web property ID. The format is UA-XXXX-Y. All collected data is associated by this ID.
     */
    this.tid = null; // the value will set in the back-end side (like UA-XXXXXXXX-X)
    /**
     * Viewport size
     * Optional.
     * Specifies the viewable area of the browser / device.
     */
    this.vp = null;
    /**
     * Cache Buster
     * Optional.
     * Used to send a random number in GET requests to ensure browsers and proxies don"t cache hits.
     * It should be sent as the final parameter of the request since we"ve seen some 3rd party internet filtering software add
     * additional parameters to HTTP requests incorrectly.
     * This value is not used in reporting.
     */
    this.z = null;
    /**
     * Session Control
     * Optional.
     * Used to control the session duration.
     * A value of "start" forces a new session to start with this hit and "end" forces the current session to end with this hit.
     * All other values are ignored.
     */
    this.sc = null;
  }
}

/** descriptions of keys see in the https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters */
class NgxTrackerService {
  constructor(document, platformId, storageService, params) {
    this.document = document;
    this.platformId = platformId;
    this.storageService = storageService;
    this.params = params;
    this.clientIdKey = '_ga';
    this.keySize = 16;
    this.window = typeof window !== 'undefined' ? window : null;
    this.encrypt = (item, keyId, ivId) => {
      const req = ['?', this.params.map(item).toString()].join('');
      const key = enc.Utf8.parse(keyId); // secret key
      const iv = enc.Utf8.parse(ivId); // 16 digit
      const reqUTF8 = enc.Utf8.parse(req);
      const option = {
        keySize: this.keySize,
        iv,
        mode: mode.CBC,
        padding: pad.Pkcs7
      };
      const encryptedText = AES.encrypt(reqUTF8, key, option);
      return encryptedText.toString();
    };
  }
  get randomValue() {
    let result = parseInt(`${1125899839733759 * Math.random()}`, 10).toString();
    result += parseInt(`${1125899839733759 * Math.random()}`, 10).toString();
    result = result.substring(0, this.keySize);
    return result;
  }
  /**
   * Client ID
   * Optional.
   * This field is required if User ID (uid) is not specified in the request.
   * This anonymously identifies a particular user, device, or browser instance.
   * For the web, this is generally stored as a first-party cookie with a two-year expiration.
   * For mobile apps, this is randomly generated for each particular instance of an application install.
   * The value of this field should be a random UUID (version 4) as described in http://www.ietf.org/rfc/rfc4122.txt.
   */
  get cid() {
    let result = null;
    if (this.document && isPlatformBrowser(this.platformId)) {
      const cookie = this.document.cookie;
      if (typeof cookie === 'string' && cookie.indexOf(this.clientIdKey) > -1) {
        const cookies = cookie.split('; ');
        if (Array.isArray(cookies)) {
          for (const cooky of cookies) {
            const item = cooky.split('=');
            if (Array.isArray(item)) {
              const key = item[0];
              const value = item[1] || '';
              if (this.clientIdKey === key) {
                const matches = value.match(new RegExp('\\d\{5\,14\}\\.\\d\{5\,14\}', 'gi'));
                if (Array.isArray(matches)) {
                  result = matches[0];
                  break;
                }
              }
            }
          }
        }
      }
    }
    if (!result) {
      result = this.storageService.get(this.clientIdKey);
      if (!result) {
        result = `${this.randomValue.substr(0, 10)}.${this.randomValue.substr(0, 10)}`;
        this.storageService.set(this.clientIdKey, result);
      }
    }
    return result;
  }
  /**
   * Document Encoding
   * Optional.
   * Specifies the character set used to encode the page / document.
   */
  get de() {
    let result = null;
    if (this.document && isPlatformBrowser(this.platformId)) {
      result = this.document.characterSet || this.document.charset;
    }
    return result;
  }
  /**
   * User Language
   * Optional.
   * Specifies the language.
   */
  get ul() {
    let result = null;
    if (this.window && this.window.navigator && isPlatformBrowser(this.platformId)) {
      const navigator = this.window.navigator;
      result = navigator && (navigator.language || navigator.browserLanguage) || '';
      result = result.toLowerCase();
    }
    return result;
  }
  /**
   * Screen Colors
   * Optional.
   * Specifies the screen color depth.
   */
  get sd() {
    let result = null;
    if (this.window && this.window.screen && isPlatformBrowser(this.platformId)) {
      result = `${this.window.screen.colorDepth}-bit`;
    }
    return result;
  }
  /**
   * Screen Resolution
   * Optional.
   * Specifies the screen resolution.
   */
  get sr() {
    let result = null;
    if (this.window && this.window.screen && isPlatformBrowser(this.platformId)) {
      result = `${this.window.screen.width + 'x' + this.window.screen.height}`;
    }
    return result;
  }
  /**
   * Viewport size
   * Optional.
   * Specifies the viewable area of the browser / device.
   */
  get vp() {
    let result = null;
    if (this.document && isPlatformBrowser(this.platformId)) {
      const documentElement = this.document.documentElement;
      if (documentElement) {
        result = `${documentElement.clientWidth + 'x' + documentElement.clientHeight}`;
      }
    }
    return result;
  }
  get trackerModel() {
    return {
      ...new TrackerModel(),
      cid: this.cid,
      de: this.de,
      sd: this.sd,
      sr: this.sr,
      ul: this.ul,
      vp: this.vp,
      z: this.randomValue.substr(0, 10)
    };
  }
  static {
    this.ɵfac = function NgxTrackerService_Factory(t) {
      return new (t || NgxTrackerService)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(PLATFORM_ID), i0.ɵɵinject(StorageService), i0.ɵɵinject(NgxHttpParamsService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxTrackerService,
      factory: NgxTrackerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTrackerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i1.StorageService,
    decorators: [{
      type: Inject,
      args: [StorageService]
    }]
  }, {
    type: i2.NgxHttpParamsService,
    decorators: [{
      type: Inject,
      args: [NgxHttpParamsService]
    }]
  }], null);
})();
class NgxTrackerComponent {
  constructor(platformId, baseHref, doc, trackerHttpService, trackerService, router, title) {
    this.platformId = platformId;
    this.baseHref = baseHref;
    this.doc = doc;
    this.trackerHttpService = trackerHttpService;
    this.trackerService = trackerService;
    this.router = router;
    this.title = title;
    this.subscription = new Subscription();
    this.url = null;
    this.setTracker = url => {
      if (isPlatformBrowser(this.platformId)) {
        const model = {
          ...this.trackerModel,
          ...this.trackerService.trackerModel,
          dl: `${this.baseHref}${url}`,
          dp: url,
          dr: this.url,
          dt: this.title.getTitle()
        };
        this.trackerHttpService.addTracker(model).subscribe();
      }
      this.url = null;
    };
    this.setUrl = () => {
      if (this.url === null) {
        this.url = `${this.baseHref}${this.router.url}`;
      }
    };
    this.getReferer = () => {
      if (isPlatformBrowser(this.platformId)) {
        this.url = this.doc.referrer || undefined;
      }
    };
  }
  ngOnInit() {
    this.getReferer();
    this.subscription.add(this.router.events.subscribe(e => {
      switch (e.constructor) {
        case NavigationStart:
          this.setUrl();
        // tslint:disable-next-line: no-switch-case-fall-through
        case NavigationEnd:
          this.setTracker(this.router.url);
      }
    }));
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  static {
    this.ɵfac = function NgxTrackerComponent_Factory(t) {
      return new (t || NgxTrackerComponent)(i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(NGX_BASE_URL), i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(NgxTrackerHttpService), i0.ɵɵdirectiveInject(NgxTrackerService), i0.ɵɵdirectiveInject(Router), i0.ɵɵdirectiveInject(Title));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgxTrackerComponent,
      selectors: [["ngx-tracker"]],
      inputs: {
        trackerModel: "trackerModel"
      },
      decls: 0,
      vars: 0,
      template: function NgxTrackerComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTrackerComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-tracker',
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGX_BASE_URL]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: NgxTrackerHttpService,
    decorators: [{
      type: Inject,
      args: [NgxTrackerHttpService]
    }]
  }, {
    type: NgxTrackerService,
    decorators: [{
      type: Inject,
      args: [NgxTrackerService]
    }]
  }, {
    type: i3.Router,
    decorators: [{
      type: Inject,
      args: [Router]
    }]
  }, {
    type: i4.Title,
    decorators: [{
      type: Inject,
      args: [Title]
    }]
  }], {
    trackerModel: [{
      type: Input
    }]
  });
})();
class NgxTrackerModule {
  static forRoot(config) {
    return {
      ngModule: NgxTrackerModule,
      providers: [config.loader || {
        provide: NgxTrackerHttpService,
        useClass: NgxTrackerHttpFakeService
      }, config.baseUrl || {
        provide: NGX_BASE_URL,
        useValue: ''
      }]
    };
  }
  static {
    this.ɵfac = function NgxTrackerModule_Factory(t) {
      return new (t || NgxTrackerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxTrackerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxTrackerModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxTrackerComponent],
      exports: [NgxTrackerComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of tracking-analytics
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NGX_BASE_URL, NgxTrackerComponent, NgxTrackerHttpFakeService, NgxTrackerHttpService, NgxTrackerModule, NgxTrackerService, TrackerModel };
