<mat-progress-bar
  color="#0099de"
  mode="query"
/>

<nav
  class="navbar navbar-expand-md navbar-light bg-white fixed-top float-right"
>
  <div class="container px-0">
    <a
      class="navbar-brand"
      [routerLink]="['/']"
    >
      <img
        src="/assets/images/mdObject-logo.svg"
        alt="mdObject logo"
      />
    </a>

    <button
      #buttonRef
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarsExampleDefault"
      aria-controls="navbarsExampleDefault"
      aria-expanded="false"
      aria-label="Toggle navigation"
      [class.collapsed]="isOpenMenu"
      (click)="isOpenMenu = !isOpenMenu"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div
      class="collapse navbar-collapse"
      [class.show]="isOpenMenu"
      id="navbarsExampleDefault"
    >
      <ul class="navbar-nav ms-auto">
        <li class="nav-item px-4">
          <a
            [navbarToggler]="buttonRef"
            [isMobile]="isMobile"
            [routerLink]="['/home']"
            [routerLinkActive]="['active']"
            class="nav-link text-dark font-weight-bold"
            >Home</a
          >
        </li>

        <li class="nav-item px-4">
          <a
            [navbarToggler]="buttonRef"
            [isMobile]="isMobile"
            [routerLink]="['/training']"
            [routerLinkActive]="['active']"
            class="nav-link text-dark font-weight-bold"
            >Training</a
          >
        </li>

        <li class="nav-item px-4">
          <a
            [navbarToggler]="buttonRef"
            [isMobile]="isMobile"
            [routerLink]="['/about']"
            [routerLinkActive]="['active']"
            class="nav-link text-dark font-weight-bold"
            >About</a
          >
        </li>

        <li class="nav-item px-4">
          <a
            [navbarToggler]="buttonRef"
            [isMobile]="isMobile"
            [routerLink]="['/our-team']"
            [routerLinkActive]="['active']"
            class="nav-link text-dark font-weight-bold"
            >Our team</a
          >
        </li>

        <li class="nav-item px-4">
          <a
            [navbarToggler]="buttonRef"
            [isMobile]="isMobile"
            [routerLink]="['/contact']"
            [routerLinkActive]="['active']"
            class="nav-link text-dark font-weight-bold"
            >Contact</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>
