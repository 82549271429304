import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'our-team',
  standalone: true,
  templateUrl: './our-team.component.html',
  imports: [RouterLink],
  styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent {}
