<main>
  <div class="container-fluid bg-white">
    <div class="container px-0 pt-3">
      <ol class="breadcrumb pt-5 pb-0 px-0 mx-0 bg-white">
        <li class="breadcrumb-item"><a [routerLink]="['/home']">Home</a></li>
        <li class="breadcrumb-item active">Contact</li>
      </ol>
    </div>
  </div>

  <div class="clearfix"></div>
  <div class="container-fluid contact clearfix">
    <div class="container px-0 py-5">
      <h1 class="text-uppercase display-3 text-center text-dark mb-4"
        >Contact <span class="text-dark">us</span></h1
      >

      <h2 class="text-uppercase mt-4 mb-2 text-center">for more info</h2>

      <div class="row">
        <div class="col-md-12 col-lg-4 float-left">
          <form
            [formGroup]="formGroup"
            (ngSubmit)="submit()"
            class="contact-form p-5 my-5"
            autocomplete="on"
          >
            <ngx-mat-input
              formControlName="name"
              placeholder="Name"
              autocomplete="given-name"
              name="fname"
              type="text"
              required
            ></ngx-mat-input>

            <ngx-mat-input
              formControlName="phone"
              placeholder="Phone"
              autocomplete="tel"
              name="tel"
              type="text"
              required
            ></ngx-mat-input>

            <ngx-mat-input
              formControlName="email"
              placeholder="Email address"
              autocomplete="email"
              name="email"
              type="email"
              required
            ></ngx-mat-input>

            <mat-form-field class="w-100">
              <textarea
                name="text"
                formControlName="message"
                matInput
                class="form-control rounded-0"
                placeholder="Message"
                required
              ></textarea>

              @if (formGroup.controls.message.errors?.required) {
                <mat-error>Please tell us about your project</mat-error>
              }

              @if (formGroup.controls.message.errors?.minlength) {
                <mat-error>
                  Message must be at least
                  {{
                    formGroup.controls.message.errors?.minlength?.requiredLength
                  }}
                  characters long.
                </mat-error>
              }
            </mat-form-field>

            <br />

            <div class="text-center recaptcha-size">
              <ngx-core-captcha
                formControlName="captcha"
                [url]="captchaUrl | async"
                
              ></ngx-core-captcha>
            </div>

            <br />

            <button
              type="submit"
              [disabled]="formGroup.invalid"
              class="btn btn-success btn-lg btn-block btn-1 rounded my-3 text-uppercase"
              >Submit</button
            >

            @if (errorMessage) {
              <p class="alert alert-danger">{{ errorMessage }}</p>
            }
          </form>
        </div>

        <div class="col d-md-none"></div>

        <div class="col-lg-6 col-md-12 float-left">
          <address class="p-5 my-5 text-white">
            <p>Support Hours: <br />M-F 10:00AM-4:00PM (PST)</p>

            <p
              >Tech Support: <br /><img
                src="/assets/images/phone-icon.png"
                alt="mdObject phone"
                class="mr-3"
              />503.348.2346</p
            >
          </address>
        </div>
        <br />
      </div>
    </div>
  </div>
</main>

<ng-template #modalRef>
  <div class="modalBody">
    <p>
      <strong>Thank you for sending us a message! We will contact you as soon as it is
        possible.
      </strong>
    </p>

    <button
      style="width:80%"
      class="btn btn-lg btn-block btn-1 rounded border border-dark text-uppercase bg-white text-dark"
      [mat-dialog-close]="true"
      >close
  </button>
  </div>
</ng-template>
