import * as i6 from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import * as i0 from '@angular/core';
import { InjectionToken, Component, ChangeDetectionStrategy, Input, EventEmitter, Inject, Optional, Self, Output, HostBinding, forwardRef, Directive, ContentChild, HostListener, NgModule } from '@angular/core';
import * as i2 from '@angular/forms';
import { UntypedFormControl, NgControl, FormGroupDirective, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i3 from '@angular/material/progress-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import * as i4 from '@renet-consulting/ngx-mat-input';
import { NgxMatInputModule } from '@renet-consulting/ngx-mat-input';
function CoreCaptchaComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3)(1, "div", 4);
    i0.ɵɵelement(2, "img", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 6)(4, "button", 7);
    i0.ɵɵlistener("click", function CoreCaptchaComponent_div_0_Template_button_click_4_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.refresh());
    });
    i0.ɵɵelement(5, "i", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(6, "ngx-core-captcha-audio", 9);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const item_r4 = ctx.ngIf;
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("src", item_r4 == null ? null : item_r4.image, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(4);
    i0.ɵɵproperty("sound", item_r4 == null ? null : item_r4.sound);
  }
}
function CoreCaptchaComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 10);
    i0.ɵɵelement(1, "mat-progress-spinner", 11);
    i0.ɵɵelementEnd();
  }
}
const NGX_CORE_CAPTCHA_OPTIONS = new InjectionToken('NGX_CORE_CAPTCHA_OPTIONS', {
  providedIn: 'root',
  factory: () => ({})
});
class CoreCaptchaAudioComponent {
  constructor() {
    /** internal */
    this.play = () => this.audio && this.audio.play();
    /** internal */
    this.pause = () => this.audio && this.audio.pause();
    /** internal */
    this.setAudio = () => {
      if (typeof Audio !== 'undefined' && this.sound) {
        this.audio = new Audio(this.sound);
      }
    };
    this.toggle = () => this.paused ? this.play() : this.pause();
  }
  ngOnChanges() {
    this.pause();
    this.setAudio();
  }
  ngOnDestroy() {
    this.pause();
  }
  /** internal */
  get paused() {
    return this.audio && this.audio.paused;
  }
  static {
    this.ɵfac = function CoreCaptchaAudioComponent_Factory(t) {
      return new (t || CoreCaptchaAudioComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CoreCaptchaAudioComponent,
      selectors: [["ngx-core-captcha-audio"]],
      inputs: {
        sound: "sound"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 2,
      vars: 0,
      consts: [["type", "button", 1, "btn", "btn-link", "p-0", "btn-lg", "text-secondary", 3, "click"], [1, "fas", "fa-headphones"]],
      template: function CoreCaptchaAudioComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "button", 0);
          i0.ɵɵlistener("click", function CoreCaptchaAudioComponent_Template_button_click_0_listener() {
            return ctx.toggle();
          });
          i0.ɵɵelement(1, "i", 1);
          i0.ɵɵelementEnd();
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoreCaptchaAudioComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-core-captcha-audio',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<button class=\"btn btn-link p-0 btn-lg text-secondary\" type=\"button\" (click)=\"toggle()\">\r\n    <i class=\"fas fa-headphones\"></i>\r\n</button>"
    }]
  }], () => [], {
    sound: [{
      type: Input
    }]
  });
})();
class CoreCaptchaComponent {
  constructor(options, http, ngControl, formGroup) {
    this.http = http;
    this.ngControl = ngControl;
    this.formGroup = formGroup;
    this.placeholder = 'Please type the text above';
    this.label = 'Core Captcha';
    this.resolved = new EventEmitter();
    this.blur = new EventEmitter();
    this.dBlock = true;
    this.subscription = new Subscription();
    this.formControl = new UntypedFormControl();
    this.updateControl = () => {
      this.formControl.markAsDirty();
      this.formControl.markAsTouched();
      this.formControl.updateValueAndValidity();
    };
    this.emitDecodedCaptcha = () => this.resolved.emit(this.value);
    this.destroy = () => {
      this.captcha = null;
      this.captchaAsync = null;
      this.formControl.reset();
    };
    this.setCaptchaAsync = () => {
      if (this.url) {
        this.destroy();
        this.captchaAsync = this.http.get(`${this.url}${this.query}`).pipe(tap(i => this.captcha = i));
      }
    };
    this.refresh = () => this.setCaptchaAsync();
    /**  override {@link ngx-mat-input} validator */
    this.setValidator = () => {
      const validator = this.ngControl && this.ngControl.control && this.ngControl.control.validator;
      if (validator) {
        this.formControl.validator = this.mapValidator(validator);
      }
    };
    this.mapValidator = validator => () => validator({
      value: this.value
    });
    this.onBlur = () => this.blur.emit(null);
    if (options) {
      this.height = options.height;
      this.url = options.url;
      this.width = options.width;
      if (options.placeholder) {
        this.placeholder = options.placeholder;
      }
    }
  }
  ngOnChanges() {
    this.setCaptchaAsync();
  }
  ngOnInit() {
    this.setCaptchaAsync();
    this.setValidator();
    if (this.formGroup) {
      this.subscription.add(this.formGroup.ngSubmit.subscribe(this.updateControl));
    }
    this.subscription.add(this.formControl.valueChanges.subscribe(this.emitDecodedCaptcha));
  }
  ngOnDestroy() {
    this.destroy();
    this.subscription.unsubscribe();
  }
  get query() {
    return this.width && this.height ? `?width=${this.width}&height=${this.height}` : '';
  }
  get value() {
    return {
      captcha: this.formControl.value,
      hash: this.captcha && this.captcha.hash
    };
  }
  static {
    this.ɵfac = function CoreCaptchaComponent_Factory(t) {
      return new (t || CoreCaptchaComponent)(i0.ɵɵdirectiveInject(NGX_CORE_CAPTCHA_OPTIONS), i0.ɵɵdirectiveInject(HttpClient), i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(FormGroupDirective, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CoreCaptchaComponent,
      selectors: [["ngx-core-captcha"]],
      hostVars: 2,
      hostBindings: function CoreCaptchaComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("d-block", ctx.dBlock);
        }
      },
      inputs: {
        height: "height",
        url: "url",
        width: "width",
        placeholder: "placeholder",
        label: "label",
        errors: "errors"
      },
      outputs: {
        resolved: "resolved",
        blur: "blur"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 5,
      vars: 8,
      consts: [["loadingRef", ""], ["class", "d-flex", 4, "ngIf", "ngIfElse"], [3, "blur", "label", "placeholder", "formControl", "errors"], [1, "d-flex"], [1, "flex-grow-1"], [3, "src"], [1, "d-flex", "align-content-between", "justify-content-center", "flex-wrap"], ["type", "button", 1, "btn", "btn-link", "btn-lg", "p-0", "text-secondary", "float-right", 3, "click"], [1, "fas", "fa-redo-alt"], [3, "sound"], [1, "d-flex", "justify-content-center"], ["mode", "indeterminate"]],
      template: function CoreCaptchaComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵtemplate(0, CoreCaptchaComponent_div_0_Template, 7, 2, "div", 1);
          i0.ɵɵpipe(1, "async");
          i0.ɵɵelementStart(2, "ngx-mat-input", 2);
          i0.ɵɵlistener("blur", function CoreCaptchaComponent_Template_ngx_mat_input_blur_2_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onBlur());
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(3, CoreCaptchaComponent_ng_template_3_Template, 2, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          const loadingRef_r5 = i0.ɵɵreference(4);
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 6, ctx.captchaAsync))("ngIfElse", loadingRef_r5);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("label", ctx.label)("placeholder", ctx.placeholder)("formControl", ctx.formControl)("errors", ctx.errors);
        }
      },
      dependencies: [i1.NgIf, i2.NgControlStatus, i2.FormControlDirective, i3.MatProgressSpinner, i4.NgxMatInputComponent, CoreCaptchaAudioComponent, i1.AsyncPipe],
      styles: ["img[_ngcontent-%COMP%]{-webkit-filter:grayscale(1) contrast(2);filter:grayscale(1) contrast(2);width:100%;height:auto}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoreCaptchaComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-core-captcha',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"d-flex\" *ngIf=\"captchaAsync | async as item; else loadingRef\">\r\n    <div class=\"flex-grow-1\"><img [src]=\"item?.image\" /></div>\r\n    <div class=\"d-flex align-content-between justify-content-center flex-wrap\">\r\n        <button class=\"btn btn-link btn-lg p-0 text-secondary float-right\" type=\"button\" (click)=\"refresh()\">\r\n            <i class=\"fas fa-redo-alt\"></i>\r\n        </button>\r\n        <ngx-core-captcha-audio [sound]=\"item?.sound\"></ngx-core-captcha-audio>\r\n    </div>\r\n</div>\r\n<ngx-mat-input [label]=\"label\" [placeholder]=\"placeholder\" [formControl]=\"formControl\" [errors]=\"errors\" (blur)=\"onBlur()\"></ngx-mat-input>\r\n\r\n<ng-template #loadingRef><div class=\"d-flex justify-content-center\"><mat-progress-spinner mode=\"indeterminate\"></mat-progress-spinner></div></ng-template>",
      styles: ["img{-webkit-filter:grayscale(1) contrast(2);filter:grayscale(1) contrast(2);width:100%;height:auto}\n"]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGX_CORE_CAPTCHA_OPTIONS]
    }]
  }, {
    type: i6.HttpClient,
    decorators: [{
      type: Inject,
      args: [HttpClient]
    }]
  }, {
    type: i2.NgControl,
    decorators: [{
      type: Optional
    }, {
      type: Self
    }, {
      type: Inject,
      args: [NgControl]
    }]
  }, {
    type: i2.FormGroupDirective,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [FormGroupDirective]
    }]
  }], {
    height: [{
      type: Input
    }],
    url: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    errors: [{
      type: Input
    }],
    resolved: [{
      type: Output
    }],
    blur: [{
      type: Output
    }],
    dBlock: [{
      type: HostBinding,
      args: ['class.d-block']
    }]
  });
})();
class CoreCaptchaDirective {
  constructor() {
    this.resolve = model => {
      if (this.onChange) {
        this.onChange(model);
      }
    };
    this.blur = () => {
      if (this.onTouched) {
        this.onTouched();
      }
    };
  }
  writeValue(value) {
    if (!value && this.host) {
      this.host.refresh();
    }
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  static {
    this.ɵfac = function CoreCaptchaDirective_Factory(t) {
      return new (t || CoreCaptchaDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CoreCaptchaDirective,
      selectors: [["ngx-core-captcha", "formControlName", ""], ["ngx-core-captcha", "formControl", ""], ["ngx-core-captcha", "ngModel", ""]],
      contentQueries: function CoreCaptchaDirective_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, CoreCaptchaComponent, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.host = _t.first);
        }
      },
      hostBindings: function CoreCaptchaDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("resolved", function CoreCaptchaDirective_resolved_HostBindingHandler($event) {
            return ctx.resolve($event);
          })("blur", function CoreCaptchaDirective_blur_HostBindingHandler() {
            return ctx.blur();
          });
        }
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CoreCaptchaDirective),
        multi: true
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoreCaptchaDirective, [{
    type: Directive,
    args: [{
      // tslint:disable-next-line
      selector: 'ngx-core-captcha[formControlName],ngx-core-captcha[formControl],ngx-core-captcha[ngModel]',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => CoreCaptchaDirective),
        multi: true
      }]
    }]
  }], () => [], {
    host: [{
      type: ContentChild,
      args: [CoreCaptchaComponent, {
        static: true
      }]
    }],
    resolve: [{
      type: HostListener,
      args: ['resolved', ['$event']]
    }],
    blur: [{
      type: HostListener,
      args: ['blur']
    }]
  });
})();
class CoreCaptchaModule {
  static {
    this.ɵfac = function CoreCaptchaModule_Factory(t) {
      return new (t || CoreCaptchaModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CoreCaptchaModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, ReactiveFormsModule, MatProgressSpinnerModule, NgxMatInputModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CoreCaptchaModule, [{
    type: NgModule,
    args: [{
      declarations: [CoreCaptchaComponent, CoreCaptchaDirective, CoreCaptchaAudioComponent],
      exports: [CoreCaptchaComponent, CoreCaptchaDirective],
      imports: [CommonModule, ReactiveFormsModule, MatProgressSpinnerModule, NgxMatInputModule]
    }]
  }], null, null);
})();
function CoreCaptchaRequired(control) {
  const model = control.value;
  return model && model.captcha ? null : {
    required: true
  };
}

/*
 * Public API Surface of core-captcha
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CoreCaptchaComponent, CoreCaptchaDirective, CoreCaptchaModule, CoreCaptchaRequired, NGX_CORE_CAPTCHA_OPTIONS };
