<header>
  <div class="jumbotron p-5 text-white mt-5">
    <div class="container">
      <div class="row">
        <div class="col-8 pl-0">
          <h1 class="display-3 mt-1">mdObject&trade;</h1>

          <h3 class="mt-4 mb-5"
            >Is a healthcare technology company that is specializing in a
            simplify medical data exchange between applications and
            organizations.</h3
          >

          <p>
            <a
              class="btn btn-1 btn-outline-light btn-lg text-uppercase text-white"
              [routerLink]="'/about'"
              role="button"
              >About us &raquo;</a
            >
          </p>
        </div>

        <div class="col"></div>
      </div>
    </div>
  </div>
</header>

<div class="clearfix"></div>

<main>
  <div class="container products mb-5">
    <div class="row d-flex align-items-stretch bd-highlight">
      <div
        class="col-xl-6 text-center text-white bg-primary bd-highlight p-5 float-left card-body"
      >
        <article>
          <img
            src="/assets/images/ge-centricity-icon.png"
            alt="GE Centricity $mdObject"
          />

          <h2 class="text-uppercase card-title"
            ><strong>GE Centricity $mdObject</strong></h2
          >

          <p class="text-left my-4 products-text card-text"
            >The $mdObject&trade;is a JavaScript/Typescript component that
            abstracts MEL functions of the GE Centricity (EMR or Practice
            Solution) into the object-oriented JSON based implementation. The
            $mdObject&trade;is the Open Source and licensed under Microsoft
            Public License (MS-PL).</p
          >

          <p
            ><a
              class="btn btn-1 btn-outline-light btn-lg text-uppercase text-white"
              [routerLink]="['/gecentricity']"
              role="button"
              >View details &raquo;</a
            ></p
          >
        </article>
      </div>

      <div
        class="col-xl-6 text-center text-white bg-dark bd-highlight p-5 float-left card-body"
      >
        <article>
          <img
            src="/assets/images/training-icon.png"
            alt="Online Training Materials"
          />

          <h2 class="text-uppercase card-title"
            ><strong>Online Training Materials</strong></h2
          >

          <p class="text-left my-4 products-text products-text-dark card-text"
            >Are you unfamiliar with GE Centricity HTML forms development? Get
            trained. Review our free online educational materials. Don't forget
            to click "Like" on every episode.</p
          >

          <p
            ><a
              class="btn btn-1 btn-outline-light btn-lg text-uppercase text-white"
              [routerLink]="['/training']"
              role="button"
              >View details &raquo;</a
            ></p
          >
        </article>
      </div>

      <!-- MDOBJ-68 -->
      <!--<div class="col-xl-4 text-center  text-white bg-success bd-highlight p-5 float-left card-body">
                <article>
                    <img src="/assets/images/simulator-icon.png" alt="KeyEnter product" />
                    <h2 class="text-uppercase card-title"><strong>KeyEnter product</strong></h2>
                    <p class="text-left my-4 products-text card-text">Are you tired to use the Ctrl+M key for every “Enter” on the Centricity® HTML form? The KeyEnter application is a solution for you. You have your “Enter” key back!</p>
                    <p><a class="btn btn-1 btn-outline-light btn-lg text-uppercase text-white" [routerLink]="['/keyenter-product']" role="button">View details &raquo;</a></p>
                </article>
            </div>-->
    </div>
  </div>

  <div class="clearfix"></div>
</main>
