/** whether @param x is boolean */
function isBoolean(x) {
    return typeof x === 'boolean';
}
/** whether @param x is function */
function isFunction(x) {
    return typeof x === 'function';
}
/** whether @param x is number and NaN */
function isNumber(x) {
    return typeof x === 'number' && !isNaN(x);
}
/** whether all passed params are number and NaN */
function isNumbers(...values) {
    return Array.isArray(values) && values.length > 0 ? values.every(x => isNumber(x)) : false;
}
/**
 * whether @param x is object
 * {@link function} can be {@link object}
 */
function isObject(x) {
    return x !== null && typeof x === 'object' || isFunction(x);
}
/** whether @param x is string */
function isString(x) {
    return typeof x === 'string';
}

/*
 * Public API Surface of util
 */

/**
 * Generated bundle index. Do not edit.
 */

export { isBoolean, isFunction, isNumber, isNumbers, isObject, isString };

