import { HttpParams } from '@angular/common/http';
import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import { isString, isNumber, isBoolean, isObject } from '@renet-consulting/util';
class NgxHttpParamsService {
  constructor() {}
  /**
   * the method maps any model {@link ItemType} to {@link HttpParams}
   * @param model an object with property type {@link string}, {@link number}, {@link Array}, {@link object}
   * @param prefix a util to set the right value of param for the property with the type {@link object}
   */
  map(model, prefix = '', addObjectName = true) {
    /** immutable */
    let params = new HttpParams();
    if (model) {
      Object.keys(model).forEach(key => {
        const value = model[key];
        const param = `${prefix}${key}`;
        if (isString(value)) {
          params = params.append(param, value);
        } else if (isNumber(value)) {
          params = params.append(param, value.toString());
        } else if (isBoolean(value)) {
          params = params.append(param, value.toString());
        } else if (Array.isArray(value)) {
          value.forEach(x => params = params.append(param, x));
        } else if (isObject(value) && value) {
          const subPrams = this.map(value, addObjectName ? `${param}.` : ``);
          subPrams.keys().forEach(subKey => params = params.append(subKey, subPrams.get(subKey)));
        }
      });
    }
    return params;
  }
  static {
    this.ɵfac = function NgxHttpParamsService_Factory(t) {
      return new (t || NgxHttpParamsService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgxHttpParamsService,
      factory: NgxHttpParamsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxHttpParamsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();

/*
 * Public API Surface of ngx-http-params
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxHttpParamsService };
