<main>
  <div class="container-fluid bg-white">
    <div class="container px-0 pt-4">
      <ol class="breadcrumb pt-5 pb-0 px-0 mx-0 bg-white">
        <li class="breadcrumb-item">
          <a [routerLink]="['/home']">Home</a>
        </li>

        <li class="breadcrumb-item active">Training</li>
      </ol>
    </div>
  </div>

  <div class="container training clearfix">
    <h1 class="text-uppercase display-3 text-center text-dark mb-4"
      ><span class="text-dark">Training</span> Materials</h1
    >

    <h2 class="mt-4 mb-5 text-center"
      >Watch the first four episodes of HTML Encounter Form Development
      series.</h2
    >

    <div class="row d-flex align-items-stretch bd-highlight">
      <div
        class="col-lg-6 text-center border border-light bd-highlight p-5 float-left"
      >
        @if (firstEpisode) {
          <div>
            <iframe
              src="https://www.youtube.com/embed/uueBRhdifSM?rel=0&autoplay=1"
              frameborder="0"
              gesture="media"
              allow="encrypted-media,autoplay"
              allowfullscreen
            ></iframe>
          </div>
        } @else {
          <img
            alt="The first episode"
            class="pointer d-block"
            src="assets/images/Episode1Video.jpg"
            (click)="firstEpisode = !firstEpisode"
          />
        }

        April 17, 2015
      </div>

      <div
        class="col-lg-6 text-center border border-light bd-highlight p-5 float-left"
      >
        @if (secondEpisode) {
          <div>
            <iframe
              src="https://www.youtube.com/embed/YEyjNxjLNN4?rel=0&autoplay=1"
              frameborder="0"
              gesture="media"
              allow="encrypted-media,autoplay"
              allowfullscreen
            ></iframe>
          </div>
        } @else {
          <img
            alt="The second episode"
            class="pointer d-block"
            src="assets/images/Episode2Video.jpg"
            (click)="secondEpisode = !secondEpisode"
          />
        }

        May 7, 2015
      </div>

      <div
        class="col-lg-6 text-center border border-light bd-highlight p-5 float-left"
      >
        @if (thirdEpisode) {
          <div>
            <iframe
              src="https://www.youtube.com/embed/WtuhusgojdE?rel=0&autoplay=1"
              frameborder="0"
              gesture="media"
              allow="encrypted-media,autoplay"
              allowfullscreen
            ></iframe>
          </div>
        } @else {
          <img
            alt="The third episode"
            class="pointer d-block"
            src="assets/images/Episode3Video.jpg"
            (click)="thirdEpisode = !thirdEpisode"
          />
        }

        January 7, 2018
      </div>

      <div
        class="col-lg-6 text-center border border-light bd-highlight p-5 float-left"
      >
        @if (fourthEpisode) {
          <div>
            <iframe
              src="https://www.youtube.com/embed/cbQ33FtNe3c?autoplay=1"
              frameborder="0"
              gesture="media"
              allow="encrypted-media,autoplay"
              allowfullscreen
            ></iframe>
          </div>
        } @else {
          <img
            alt="The fourth episode"
            class="pointer d-block"
            src="assets/images/Episode4Video.jpg"
            (click)="fourthEpisode = !fourthEpisode"
          />
        }

        August 20, 2020
      </div>

      <div
        class="col-lg-4 text-center border border-light bd-highlight p-5 float-left"
      >
      </div>

      <div
        class="col-lg-4 text-center border border-light bd-highlight p-5 float-left"
      >
      </div>

      <div class="clearfix"></div>
    </div>
  </div>
</main>
